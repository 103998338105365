<template>
  <form id="newRecord" @submit.prevent="saveOrUpdateVerificacion">
    <c-422-errors :errors="errors" />
    <div class="mb-3">
      <label for="name" class="form-label">Nombre:</label>
      <input
        v-model="verificacion.name"
        class="form-control"
        id="name"
        required
      />
    </div>
    <div class="mb-3">
      <label for="description" class="form-label">Descripción:</label>
      <input
        v-model="verificacion.description"
        class="form-control"
        id="description"
      />
    </div>
    <c-primary-button class="w-100" :disabled="loading">
      Guardar
    </c-primary-button>
  </form>
</template>
<script>
import CPrimaryButton from "@/components/CPrimaryButton.vue";
import C422Errors from "@/components/C422Errors";
import { create, update, find } from "@/services/verificaciones";
import { showNotification } from "@/services/notification";

export default {
  components: {
    C422Errors,
    CPrimaryButton,
  },
  data() {
    return {
      errors: {},
      loading: false,
      verificacion: {
        name: "",
        description: "",
      },
      verificacionId: this.$route.params.id,
    };
  },
  mounted() {
    this.getVerificacion();
  },
  methods: {
    getVerificacion() {
      if (!this.verificacionId) {
        return;
      }
      find(this.verificacionId).then((response) => {
        this.verificacion = response.data;
      });
    },
    saveOrUpdateVerificacion() {
      if (this.verificacionId) {
        this.updateVerificacion();
      } else {
        this.saveVerificacion();
      }
    },
    saveVerificacion() {
      create(this.verificacion).then(() => {
        showNotification(201);
        this.$router.push("/admin/configuraciones/tipos-de-verificacion");
      });
    },
    updateVerificacion() {
      update(this.verificacionId, this.verificacion).then(() => {
        showNotification(201);
        this.$router.push("/admin/configuraciones/tipos-de-verificacion");
      });
    },
  },
};
</script>
